import React from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const Footer = () => {
  const { logo } = useStaticQuery(
    graphql`{
  logo: file(relativePath: {eq: "logo_white.png"}) {
    childImageSharp {
      gatsbyImageData(width: 350, quality: 100, layout: CONSTRAINED)
    }
  }
}
`
  )

  return (
    <footer className="pt-5 mt-5 bg-blue">
      <Container fluid>
        <div className="row">
          <div className="col-md-4">
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
          </div>
          <div className="col-md-4 mt-5 mt-md-0">
            <h4 className="h6">Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG</h4>
            <p>
              Mit uns zeigt sich Ihre Immobilie von ihrer besten Seite! Wir erstellen hochwertige Exposés und geben Ihnen fundierte Tipps, wie Sie Ihre Immobilie auch vor Ort perfekt in Szene setzen. Wir arrangieren Besichtigungen und beantworten bei Führungen fachkundig alle Fragen der Interessenten.
            </p>
          </div>
          <div className="col-md-4 mt-5 mt-md-0">
            <h4 className="h6">Standort Halstenbek</h4>
            <p>
              Hauptstraße 22A<br/>
              25469 Halstenbek<br/>
              Tel.: 04101 - 58 63 738 
            </p>
          </div>
        </div>
      </Container>
      <div className="bottomBar mt-5 py-2 text-center">
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <Link to="/datenschutzerklaerung">Datenschutz</Link>
              <Link to="/impressum">Impressum</Link>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer
