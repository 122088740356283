import React from "react"
import { Container } from "react-bootstrap"
import MainNav from "./mainNav"

const Header = ({ siteMap }) => (
  <header>
    <Container fluid>
      <MainNav siteMap={siteMap} />
    </Container>
  </header>
)

export default Header
