import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import CookieConsent, { Cookies } from "react-cookie-consent"
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, siteMap }) => {
  const setCookies = () => {
    Cookies.set('gatsby-gdpr-google-tagmanager', true)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div>
            <Header siteMap={siteMap} />
            {children}
            <Footer />
          </div>
          <div className="whatsapp">
            <WhatsAppWidget 
              phoneNumber="01725907055"
              textReplyTime="Antwortet innerhalb weniger Minuten"
              companyName="Hanseatisches Immobilienkontor"
              message="Kontaktieren Sie uns für eine ausführliche Bewertung."
              sendButton="Senden"
              placeholder="Schreiben Sie uns eine Nachricht"
            />
          </div>
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            declineButtonText="Ablehnen"
            onAccept={() => setCookies()}>
              Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können. 
          </CookieConsent>
          <script src="/tracking.js"></script>
        </>
      )}
    />
  )
}

export default Layout
